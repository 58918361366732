module.exports = {
    hosts: [
        'localhost',
        '*.dev.efonds.ag',
        'fdb-mzm-ui.efonds.com', // PREPROD alpha
        'mzm2.efonds.com', // PREPROD beta
        'fdb.efonds.com', // PREPROD final config
        '*.preprod.efonds.ag',
        '*.prod.efonds.ag',
        'handel.deutsche-zweitmarkt.de'
    ],
    title: 'Deutsche Zweitmarkt AG - Ihr professionelles Fondshandelskontor',
    name: 'Deutsche Zweitmarkt - Fondshandelskontor',
    favicon: 'favicon-meinhandel.png',
}
